<template>
   <div class="sm:px-4">
      <!-- <SingleCustomer /> -->
         <div class="pb-10">

         <section class="mb-10" v-if="HomepageVisuals.length != 0">
            <p class="text-2xl mb-10 font-bold">Homepage Visuals</p>
            <div class="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-10">
               <article v-for="HomepageVisual in HomepageVisuals" :key="HomepageVisual.id" class="shadow-lg">
                  <a :href="'/visual/' + this.$route.params.id + '/homepage-visuals/' + HomepageVisual.id">
                     <img class="mx-auto" :src="HomepageVisual.CoverImage.data.attributes.url" alt="">
                     <p class="text-l font-bold text-center mb-0 bg-gray-200 p-2">{{ HomepageVisual.VisualName }}</p>
                  </a>
               </article>
            </div>
         </section>

         <section class="mb-10" v-if="LandingPageVisuals.length != 0">
            <p class="text-2xl mb-3 font-bold">Landing Page Visuals</p>
            <div class="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-10">
               <article v-for="LandingPageVisual in LandingPageVisuals" :key="LandingPageVisual.id" class="shadow-lg">
                  <a :href="'/visual/' + this.$route.params.id + '/landing-page-visuals/' + LandingPageVisual.id">
                     <img class="mx-auto" :src="LandingPageVisual.CoverImage.data.attributes.url" alt="">
                     <p class="text-l font-bold text-center mb-0 bg-gray-200 p-2">{{ LandingPageVisual.VisualName }}</p>
                  </a>
               </article>
            </div>
         </section>

         <section v-if="HeroSectionVisuals.length != 0">
            <p class="text-2xl mb-3 font-bold">Hero Section Visuals</p>
            <div class="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-2 gap-10">
               <article v-for="HeroSectionVisual in HeroSectionVisuals" :key="HeroSectionVisual.id" class="shadow-lg">
                  <a :href="'/visual/' + this.$route.params.id + '/hero-section-visuals/' + HeroSectionVisual.id">
                     <img class="mx-auto" :src="HeroSectionVisual.VisualImage.data.attributes.url" alt="">
                     <p class="text-l font-bold text-center mb-0 bg-gray-200 p-2">{{ HeroSectionVisual.VisualName }}</p>
                  </a>
               </article>
            </div>
         </section>

      </div>
   </div>
</template>

<script>
import axios from 'axios';

export default {
  data () {
   return {
      loading: false,
      CompanyName: null,
      // LogoURL: null,
      HomepageVisuals: [],
      // HomepageVisualImages: [],
      HeroSectionVisuals: [],
      LandingPageVisuals: [],
      error: null,
      fetchedId: null
   }
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {
      this.error = null
      this.loading = true
      this.fetchedId = this.$route.params.id

      axios
         .get('https://tig-visuals-backend.herokuapp.com/api/visuals/'+ this.$route.params.id +'/?populate=Logo&populate=HomepageVisuals.VisualImage&populate=HomepageVisuals.CoverImage&populate=HeroSectionVisuals.VisualImage&populate=LandingPageVisuals.VisualImage&populate=LandingPageVisuals.CoverImage')
         .then(response => {
            this.CompanyName = response.data.data.attributes.CompanyName
            // this.LogoURL = response.data.data.attributes.Logo.data.attributes.formats.thumbnail.url
            this.HomepageVisuals = response.data.data.attributes.HomepageVisuals
            this.HeroSectionVisuals = response.data.data.attributes.HeroSectionVisuals
            this.LandingPageVisuals = response.data.data.attributes.LandingPageVisuals
         })
         this.loading = false
    }
  }
}
</script>
<template>
   <div class="sm:px-4">
      <p class="mb-10"><a :href="/visual/ + this.$route.params.id" class="underline">Back to Visuals</a></p>
      <div v-for="visual in visuals" :key="visual.id" class="mb-10">
         <div v-if="visual.id === parseInt(this.$route.params.homepageId)">
            <img :src="visual.VisualImage.data.attributes.url" alt="Visual" />
         </div>
      </div>
   </div>
</template>

<script>
import axios from 'axios';

export default {
  data () {
    return {
      loading: false,
      visuals: null,
      error: null
    }
  },
  created () {
    // fetch the data when the view is created and the data is
    // already being observed
    this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    fetchData () {
      this.error = null
      this.loading = true
      axios
         .get('https://tig-visuals-backend.herokuapp.com/api/visuals/' + this.$route.params.id + '/?&populate=HomepageVisuals.VisualImage')
         .then(response => {
            this.visuals = response.data.data.attributes.HomepageVisuals
         })
         this.loading = false
    }
  }
}
</script>
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import SingleCustomer from '../views/SingleCustomer.vue'
import SingleHomepageVisual from '../views/SingleHomepageVisual.vue'
import SingleHeroVisuals from '../views/SingleHeroVisuals.vue'
import LandingPageVisuals from '../views/LandingPageVisuals.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
     path: '/visual/:id',
     component: SingleCustomer
  },
  {
     path: '/visual/:id/homepage-visuals/:homepageId',
     component: SingleHomepageVisual
  },
  {
     path: '/visual/:id/hero-section-visuals/:heroId',
     component: SingleHeroVisuals
  },
  {
     path: '/visual/:id/landing-page-visuals/:landingId',
     component: LandingPageVisuals
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router

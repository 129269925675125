<template>
   <nav class="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded">
      <div class="container flex flex-wrap items-center mx-auto">
         <a href="#" class="flex">
            <img src="https://www.tig.uk.net/wp-content/uploads/thegem-logos/logo_c61892dbad917a1d7725965945ffea48_1x.png" width="200" alt="">
         </a>
         <button data-collapse-toggle="mobile-menu" type="button" class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
            <span class="sr-only">Open main menu</span>
            <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
            <svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
         </button>
         <div class="hidden w-full md:block md:w-auto pl-10" id="mobile-menu">
            <ul class="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
               <!-- <li>
                  <router-link to="/">Home</router-link>
               </li> -->
               <!-- <li>
                  <router-link to="/about">About</router-link>
               </li> -->
            </ul>
         </div>
      </div>
   </nav>
   <div class="py-10 px-2 sm:px-4 bg-gray-200 mb-10">
      <div class="container mx-auto">
         <h1 class="text-7xl font-light mb-0" v-if="CompanyName">{{ CompanyName }} <small class="text-gray-500 font-normal">Visuals</small></h1>
         <h1 class="text-7xl font-light mb-0" v-else>TIG Customer Visuals</h1>
      </div>
   </div>
</template>

<script>
import axios from 'axios';

export default {
   name: 'Header',
   data(){
      return{
         CompanyName: '',
      }
   },
   created () {
      this.fetchData()
   },
   watch: {
      '$route': 'fetchData'
   },
   methods: {
      fetchData () {
      this.error = null
      this.loading = true
      axios
         .get('https://tig-visuals-backend.herokuapp.com/api/visuals/' + this.$route.params.id)
         .then(response => {
            this.CompanyName = response.data.data.attributes.CompanyName
         })
      }
      
   }
}
</script>
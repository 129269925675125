<template>
   <div class="grid grid-cols-4 gap-10 sm:px-4">
      <div v-for="customer in customers" :key="customer.id" class="customer p-2">
         <a :href="'/visual/'+ customer.id">
         <div class="shadow-lg h-full">
            <div class="rounded h-full flex flex-col justify-center overflow-hidden p-4">
               <img :src="customer.attributes.Logo.data.attributes.url" alt="">
            </div>
            <div class="bg-gray-200 p-2 mb-0 shadow-lg">
               <p class="font-bold text-l text-center mb-0">{{ customer.attributes.CompanyName }}</p>
            </div>
         </div>
         </a>
      </div>
   </div>
</template>

<script>
import axios from 'axios';

export default {
   data () {
      return {
         loading: false,
         customers: null,
         singleCustomer: null
      }
   },
   created () {
      this.loading = true
      axios
         .get('https://tig-visuals-backend.herokuapp.com/api/visuals?populate=Logo&populate=HomepageVisuals.VisualImage&populate=HeroSectionVisuals.VisualImage&populate=LandingPageVisuals.VisualImage')
         .then(response => ( this.customers = response.data.data ))
      this.loading = false
   }
}
</script>